.Grid {
  height: 100%;

  .header {
    padding: 10px 0;
  }

  .filter {
    padding: 10px 0;
    display: flex;

    .label {
      position: relative;
      display: block;

      letter-spacing: 0.5px;
      text-transform: uppercase;
      vertical-align: middle;
      font-family: 'Open Sans';
      color: #666;
      font-size: 12px !important;
    }

    .search {
      width: 200px;
    }
  }

  /* SHOW GRID */
  @media screen and (min-width: 1200px) {
    padding: 10px 30px;

    .filter {
      > div {
        padding-right: 20px;
      }
      > div:last-child {
        padding-right: 0px;
      }
    }

    //.p-datatable-scrollable-wrapper {
    //  min-height: calc(100vh - 210px);
    //}
  }

  /* SHOW MOBILE LIST */
  @media screen and (max-width: 1199px) {
    padding: 10px;

    .filter {
      flex-direction: column;

      > div {
        padding-bottom: 15px;
      }

      .search {
        width: 100%;
      }
    }

    .mobileList {
      background-color: #ffffff;
      border: 1px solid #cccccc;
      border-top: none;

      overflow: scroll;
      height: 50vh;

      .item {
        cursor: pointer;
        padding: 15px;
      }

      .even {
        background-color: #f2f2f2;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
      }

      .button {
        position: fixed;
        bottom: 1.5em;
        right: 1.5em;
        z-index: 1000;
      }
    }
  }
}