.Spinner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background:rgba(128, 128, 128, 0.2);
  z-index: 11999;


  display: flex;

  .p-progress-spinner {
    align-self: center;
  }

  //
  //img {
  //  position: fixed;
  //  top: 45%;
  //  left: 50%;
  //  width: 200px;
  //  height: 200px;
  //  margin-top: -100px; /* Half the height */
  //  margin-left: -100px; /* Half the width */
  //  opacity: 1.0 !important;
  //}
}