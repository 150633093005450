.SignInScreen {
  text-align: center !important;
  border: none !important;
  background-color: white !important;
  height: 100%;
  padding: 5% 0 0 0 !important;
  margin: 0 !important;

  .logo-container img {
    max-width: 310px;
    margin-bottom: 30px;
  }

  .credBox {
    width: 300px !important;
    text-align: left;
    margin: 0 auto 20px auto;
    overflow: hidden;
  }

  .errorMsg {
    margin-bottom: 30px;
    color: red;
    font-weight: bold;
  }

  .a-btn {
    color: #215ca0;
    background-color: transparent;
    border: none !important;
    margin: 0;
    padding: 0;
  }

  input[type=submit], input[type=button], button {
    margin: 0 15px 0 0;
    cursor: pointer;
    //height: 26px;
    //padding: 7px;
    border: 1px solid #cccccc;
  }

}