html {
  max-width: 1600px;
  margin: 0 auto;

  body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #fefefe;

    h1 {
      font-size: 24px;
    }

  }
}


/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
/*    monospace;*/
/*}*/

/*button {*/
/*  cursor: pointer;*/
/*}*/

/*h1 {*/
/*  font-size: 24px;*/
/*}*/

/*h2 {*/
/*  margin-top: 15px;*/
/*}*/

/*h3 {*/
/*  margin-top: 10px;*/
/*}*/

