
.EditForm {
  margin: 30px;
  border: 1px solid #ccc;
  background-color: #ffffff;
  padding: 20px;
  max-width: 100%;


  .formOfThree {
    display: flex;

    .column {
      margin-right: 100px;
    }
  }

  .formItem {
    margin: 15px 0;


    .p-inputtext {
      width: 100%;
    }

    label:first-child {
      position: relative;
      display: block;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      vertical-align: middle;
      font-family: 'Open Sans';
      color: #666;
      font-size: 12px !important;
    }
  }



  input[type=checkbox]
  {
    /* Double-sized Checkboxes */
    -ms-transform: scale(1.8); /* IE */
    -moz-transform: scale(1.8); /* FF */
    -webkit-transform: scale(1.8); /* Safari and Chrome */
    -o-transform: scale(1.8); /* Opera */
    transform: scale(1.8);
    padding: 10px;
    margin: 10px 6px;
  }

  .input-small {
    width: 200px;
  }

  .input-medium {
    width: 500px;
  }

  .input-large {
    width: 800px;
  }

  select {
    padding: 5px;
    border: 1px solid #cccccc;
    border-radius: 3px;
  }

  @media screen and (max-width: 1199px) {
    .EditForm {
      margin: 10px;
    }
  }

  .EditForm .headline {
    padding: 10px 0;
  }

  .EditForm .p-radiobutton-label {
    padding-right: 20px;
  }

  .EditForm .p-button {
    margin-right: 7px;
  }

}

.dialog {
  .EditForm {
    margin: 0;
    border: none;
    background-color: #ffffff;
    padding: 10px;


    input[type=submit], input[type=button]
    {
      margin: 0 0 0 15px;
      cursor: pointer;
    }
  }
}