.App {
  position: fixed;
  background-color: #f9f9f9;
  width: 100%;
  max-width: 1600px;
  height: 100%;
  -webkit-box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.87);
  box-shadow: 0px 0px 26px 0px rgba(0, 0, 0, 0.87);
  z-index: -1000;

  .container {
    max-width: 1600px;
    height: 100%;
  }

  .p-sortable-column-icon {
    display: none;
  }

  .p-component, .p-inputtext, .p-link, .p-menuitem-text {
    font-size: 12px !important;
    font-family: 'Roboto', sans-serif !important;
  }

  .p-datatable .p-datatable-tbody > tr > td {
    //line-height: 26px;
    padding: 10px 10px;
    //vertical-align: top;
  }

  .p-cell-editing {
    padding: 0 !important;
  }


  a:link {
    color: inherit;
  }
  a:visited {
    color: inherit;
  }

  input[type=date] {
    font-size: 12px !important;
    font-family: 'Roboto', sans-serif !important;
    height: 25px;
  }

  @media screen and (min-width: 1200px) {
    .content {
      padding-top: 80px;
      height: 100%;
      overflow-y: scroll;
    }
  }
  @media screen and (max-width: 1199px) {
    .content {
      padding-top: 50px;
    }
  }

  .alignLeft {
    text-align: left !important;
  }

  .alignRight {
    text-align: right !important;
  }


  ul {
    list-style-type: none;
  }

  li {
    padding: 7px;
  }

  .p-datatable .p-datatable-thead > tr > th {
    text-align: left;
  }


  input[type=submit], input[type=button], button {
    margin: 0 15px 0 0;
    cursor: pointer;
  }


  /* Hide Arrows From Input Number */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important;
  }

  input {
    padding: 7px;
    border: 1px solid #cccccc;
    border-radius: 3px;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .save-btn {
    color: green;
  }

  .cancel-btn {
    color: black;
  }

  .remove-btn {
    color: red;
  }

  .add-btn {
    color: blue;
  }

  .icon-btn {
    border: none;
    background-color: transparent;
  }

  .danger-btn {
    background-color: #ffe399;
  }

  .excel-icon-btn {
    border: none;
    background-color: transparent;
    width: 24px;
    background: url(./resources/icons/excel.svg) no-repeat top left;
    outline: none;
  }


  .button-row {
    display: flex;

    .divider {
      flex-grow: 1;
    }

    .left-buttons {
      display: flex;

      .p-button {
        margin-right: 15px;
      }
    }

    .right-buttons {
      display: flex;

      .p-button {
        margin-left: 15px;
      }
    }
  }

  .filter {
    input[type=submit], input[type=button], button {
      line-height: 9px;
    }
  }

  .nowrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .sum-table {
    border-spacing: 0;
    border-collapse: collapse;

    td {
      border-bottom: 1px solid #cccccc;
      padding: 5px;
      font-size: 14px;
      font-family: 'Roboto', sans-serif !important;
    }
  }
}