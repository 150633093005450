.ChangePassword {


  .password-input {
    width: 500px;
  }

  .ReactPasswordStrength-strength-desc {
    width: 200px;
  }

  input {
    border: none;
    padding: 12px;
  }
}