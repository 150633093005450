.CompanySwitch {

  @media screen and (min-width: 700px) {
    .p-dialog {
      width: 400px !important;
      background-color: red;
    }
  }

  @media screen and (max-width: 699px) {
    .p-dialog {
      width: 100% !important;
      overflow: scroll;
      max-height: 100%;

    }
  }
}