.OptionalDate {

  .checkbox {
    margin-right: 5px;
  }

  input {
    font-size: 12px !important;
    padding-left: 5px;
  }
}