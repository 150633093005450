.Alert {
  position: fixed;
  top: 30%;
  left: calc(50% - 250px);
  width: 500px;
  border: 1px solid #aaa;
  background-color: #fefefe;
  -webkit-box-shadow: 0px 0px 126px 0px rgba(0, 0, 0, 0.87);
  box-shadow: 0px 0px 126px 20px rgba(0, 0, 0, 0.87);
  z-index: 9999999;

  .header {
    display: flex;
    align-items: center;
    padding: 0 20px;

    i {
      margin-right: 15px;
    }
  }

  .message {
    padding: 15px 20px;
  }

  .footer {
    padding: 20px;
    text-align: right;

    input[type=submit], input[type=button], button {
      //margin: 0 15px 0 0;
      cursor: pointer;
      //height: 26px;
      padding: 7px;
      border: 1px solid #cccccc;
    }
  }
}