.Header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 100;

  .new-version {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    align-items: center;
    justify-content: center;

    background-color: red;
    color: white;
    font-size: 24px;
    font-weight: bold;

    > div {
      margin-bottom: 10px;
    }
  }

  /* DESKTOP */
  @media screen and (min-width: 1200px) {
    .desktop {
      max-width: 1600px;
      margin: 0 auto;
      text-align: left;
      background-color: #ffffff;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: left;
      color: #215ca0;
      font-weight: bold !important;
      padding-left: 30px;
      border-bottom: 1px solid rgba(33, 92, 160, 0.18);

      font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
      font-weight: 400;
      font-style: normal;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      img {
        width: 100px;
      }

      .nav {
        display: flex;
        padding-left: 100px;
        width: 100%;

        li {
          margin: 0 10px;
          padding: 10px;
          border: 2px solid transparent;
        }

        li.active {
          color: #215ca0 !important;
          border: 2px solid #215ca0;
          border-radius: 100px;
          background-color: rgba(33, 92, 160, 0.1);
        }

        li:hover {
          cursor: pointer;
        }

        li a {
          text-decoration: none;
        }

        //li a:hover {
        //  color: #61dafb;
        //}
      }
    }
  }
}

.Header-UserMenu {
  margin-right: 30px;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 13px;

  > div {
    display: flex;
    flex-direction: column;
  }

  span {
    white-space: nowrap;
  }
}


/*

.Header .selectedCompany {
    color: #215ca0;
    font-size: 12px;
}



@media screen and (min-width: 1200px) {
}

.MobileHeader {
    text-align: left;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 500;
}

.MobileHeader-Container {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #000000;
  padding: 0 10px;
}

.MobileHeader-CenterBox {
  flex-grow: 1;
  text-align: center;
}

.MobileHeader-RightBox {
  width: 33px;
}
*/